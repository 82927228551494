import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../components/Layout';
import Available from '../components/Available';
import Technologies from '../components/Technologies';
export const _frontmatter = {
  "templateKey": "index-page",
  "title": "Mitesh's Blog",
  "image": "/img/home-jumbotron.jpg",
  "heading": "Mitesh's Blog"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Hi 👋`}</h1>
    <p>{`I'm Mitesh Shah and I love to build stuff. I am a startup CTO, full stack software engineer, game maker and music composer. I also dabble - in coffee, wine, science fiction, writing, food plating + photography and legos. Got ideas? Let's talk!`}</p>
    <Available availableFreelance={false} availableFullTime={false} mdxType="Available" />
    <Technologies mdxType="Technologies" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      